import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Helmet bodyAttributes={{ class: `page error 404` }} />
    <article>
      <section className={`cc mt10`}>
        <div className={`wrapper flex flex-column`}>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </section>
    </article>
  </Layout>
)

export default NotFoundPage
